/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const RocketseatIcon: React.FC = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 41 41" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.114 25.417a5647.58 5647.58 0 00-4.091-2.522l-4.09-2.524a.115.115 0 00-.175.095c-.01.185-.022.455-.029.606a.36.36 0 00.054.208l.94 1.51a.673.673 0 01.079.522l-.663 2.577a.27.27 0 00.092.28c.342.273.765.575 1.274.889.51.314.969.556 1.366.738a.265.265 0 00.288-.044l1.967-1.773a.656.656 0 01.497-.167l1.758.154a.352.352 0 00.208-.046l.522-.303a.117.117 0 00.003-.2zM14.553 28.677a.652.652 0 00-.353-.477 14.907 14.907 0 01-1.056-.582c-.114-.07-.528-.327-1-.674a.652.652 0 00-.586-.096l-.114.036a2.468 2.468 0 00-1.623 1.693L7.8 35.907c-.068.245.227.424.413.253l5.595-5.147a2.467 2.467 0 00.764-2.217l-.02-.119zM33.49 6.299c.24-.32.096-.562-.012-.661a.867.867 0 00-.41-.178l-3.48-.53a.878.878 0 00-.84.35L26.85 7.868a.885.885 0 00.148 1.204c.024.021.031.058.014.086a.067.067 0 01-.067.031l-2.277-.347a.998.998 0 00-.955.398l-5.551 7.566a.235.235 0 01-.311.062l-5.344-3.245a.734.734 0 00-1.02.268c-.2.353-.058.806.288 1.016l.138.084c.069.042.105.12.094.2l-.163 1.08a.146.146 0 01-.22.105l-.782-.468a1.338 1.338 0 00-1.845.447 1.354 1.354 0 00.253 1.71c.015.014.153.121.189.143l.002.002 3.992 2.457c2.158 1.31 3.798.236 4.31-.15.455-.342.971-.72 1.833-.194a.978.978 0 001.009-.003l.993-.592a.922.922 0 01.95.004l3.028 1.838a.268.268 0 00.355-.07l7.399-10.07c.603-.808.156-1.202-.211-1.298-.024-.006-.047-.012-.071-.016l-1.775-.27a.216.216 0 01-.076-.03.211.211 0 01-.07-.29L33.49 6.3z"
        fill="#fff"
      />
    </svg>
  );
};
export const ListsIcon: React.FC = (props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M19.75 22H4.25C3.01 22 2 20.99 2 19.75V4.25C2 3.01 3.01 2 4.25 2h15.5C20.99 2 22 3.01 22 4.25v15.5c0 1.24-1.01 2.25-2.25 2.25zM4.25 3.5c-.414 0-.75.337-.75.75v15.5c0 .413.336.75.75.75h15.5c.414 0 .75-.337.75-.75V4.25c0-.413-.336-.75-.75-.75H4.25z" />
        <path d="M17 8.64H7c-.414 0-.75-.337-.75-.75s.336-.75.75-.75h10c.414 0 .75.335.75.75s-.336.75-.75.75zm0 4.11H7c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75zm-5 4.11H7c-.414 0-.75-.335-.75-.75s.336-.75.75-.75h5c.414 0 .75.337.75.75s-.336.75-.75.75z" />
      </g>
    </svg>
  );
};
export const TweetIcon: React.FC = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 41 41" fill="none" {...props}>
      <g>
        <path
          d="M8.8 7.2H5.6V3.9c0-.4-.3-.8-.8-.8s-.7.4-.7.8v3.3H.8c-.4 0-.8.3-.8.8s.3.8.8.8h3.3v3.3c0 .4.3.8.8.8s.8-.3.8-.8V8.7H9c.4 0 .8-.3.8-.8s-.5-.7-1-.7zm15-4.9v-.1h-.1c-.1 0-9.2 1.2-14.4 11.7-3.8 7.6-3.6 9.9-3.3 9.9.3.1 3.4-6.5 6.7-9.2 5.2-1.1 6.6-3.6 6.6-3.6s-1.5.2-2.1.2c-.8 0-1.4-.2-1.7-.3 1.3-1.2 2.4-1.5 3.5-1.7.9-.2 1.8-.4 3-1.2 2.2-1.6 1.9-5.5 1.8-5.7z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export const DotsCircleHorizontal: React.FC = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          fill="curent-color"
          d="M16.5 10.25c-.965 0-1.75.787-1.75 1.75s.784 1.75 1.75 1.75c.964 0 1.75-.786 1.75-1.75s-.786-1.75-1.75-1.75zm0 2.5c-.414 0-.75-.336-.75-.75 0-.413.337-.75.75-.75s.75.336.75.75c0 .413-.336.75-.75.75zm-4.5-2.5c-.966 0-1.75.787-1.75 1.75s.785 1.75 1.75 1.75 1.75-.786 1.75-1.75-.784-1.75-1.75-1.75zm0 2.5c-.414 0-.75-.336-.75-.75 0-.413.337-.75.75-.75s.75.336.75.75c0 .413-.336.75-.75.75zm-4.5-2.5c-.965 0-1.75.787-1.75 1.75s.785 1.75 1.75 1.75c.964 0 1.75-.786 1.75-1.75s-.787-1.75-1.75-1.75zm0 2.5c-.414 0-.75-.336-.75-.75 0-.413.337-.75.75-.75s.75.336.75.75c0 .413-.336.75-.75.75z"
        />
        <path
          fill="curent-color"
          d="M12 22.75C6.072 22.75 1.25 17.928 1.25 12S6.072 1.25 12 1.25 22.75 6.072 22.75 12 17.928 22.75 12 22.75zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75z"
        />
      </g>
    </svg>
  );
};

export const Comment: React.FC = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          fill="#6e767d"
          d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
        />
      </g>
    </svg>
  );
};

export const Retweet: React.FC = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          fill="curent-color"
          d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"
        />
      </g>
    </svg>
  );
};

export const Share: React.FC = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          fill="curent-color"
          d="M17.53 7.47l-5-5c-.293-.293-.768-.293-1.06 0l-5 5c-.294.293-.294.768 0 1.06s.767.294 1.06 0l3.72-3.72V15c0 .414.336.75.75.75s.75-.336.75-.75V4.81l3.72 3.72c.146.147.338.22.53.22s.384-.072.53-.22c.293-.293.293-.767 0-1.06z"
        />
        <path
          fill="curent-color"
          d="M19.708 21.944H4.292C3.028 21.944 2 20.916 2 19.652V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 .437.355.792.792.792h15.416c.437 0 .792-.355.792-.792V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 1.264-1.028 2.292-2.292 2.292z"
        />
      </g>
    </svg>
  );
};
